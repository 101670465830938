import React, { useState, useEffect } from 'react';
import CrudTable from '../table/crudtable'
import { formService, authService } from '../../_services'
import FormForm from './formform.component'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import Toast from 'react-bootstrap/Toast'

function Form() {

	const [showToast, setShowToast] = useState(false);
	const {t} = useTranslation('common');

	const initialState = {
		customerId: -1,
		name: '',
		description: '',
		header: {},
		footer: {},
		recaptcha: true,
		template: false,
		items: [],
		languages: ['SV']
	}

	useEffect(() => {
    }, [])

	const descriptionFormatter = function(cell) {
	    return cell || '-'
	}

	const linkFormatter = function(cell, row) {
		var url = window.location.protocol + '//' + window.location.host + '/submit?form=' + row.id
	    return <>
	        <a href={url} target="blank">
	            {url}
	        </a>
	        &nbsp;
	        <a title={t('form.copylink')} href="#" onClick={e => copyURL(e, row.id)}>
	            <FontAwesomeIcon icon={faClipboard}/>
	        </a>
	    </>
	}

	const copyURL = (e, formId) => {
		e.preventDefault()

		var url = window.location.protocol + '//' + window.location.host + '/submit?form=' + formId

        const textArea = document.createElement("textarea");
        textArea.innerText = url;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        textArea.remove();

        setShowToast(true)
    }

	const columns = [
		{
			dataField: 'customerName',
			text: t('form.columns.customer'),
			sort: true,
			hidden: !authService.hasRoles(['ROLE_CUSTOMER_READ'])
		},
		{
			dataField: 'name',
			text: t('form.columns.name'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('form.columns.description'),
			formatter: descriptionFormatter
		},
		{
			dataField: 'link',
			text: t('form.columns.link'),
			formatter: linkFormatter
		}
	]

	return (
	    <div>
	        <h2>
	            {t('form.label')}
	        </h2>
		    <CrudTable
		        service={formService}
		        columns={columns}
		        initialState={initialState}
		        form={<FormForm />}
		        remote={{"filter": false, "search": false, "pagination": false}}
		        sort={{dataField: 'customerName', order: 'asc'}}
		        modalSize="xl"
		        creatable={authService.hasRoles(['ROLE_FORM_WRITE'])}
		        editable={authService.hasRoles(['ROLE_FORM_WRITE'])}
		        deletable={authService.hasRoles(['ROLE_FORM_WRITE'])}
		    />
		    <Toast show={showToast} onClose={() => setShowToast(false)} delay={1000} autohide style={{
                position: 'fixed',
                left: '50%',
                transform: 'translate(-50%, 0px)'
            }}>
			    <Toast.Body>
				    {t('form.linkcopied')}
			    </Toast.Body>
			</Toast>
	    </div>
    )
}

export default Form;